import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-help-edit-my-expense',
  templateUrl: './help-edit-my-expense.page.html',
  styleUrls: ['./help-edit-my-expense.page.scss'],
})
export class HelpEditMyExpensePage implements OnInit {

  constructor(
    private modalController:ModalController
  ) { }

  ngOnInit() {
  }


  onClick(){
    this.modalController.dismiss();

  }

}
