import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-help-newclient',
  templateUrl: './help-newclient.page.html',
  styleUrls: ['./help-newclient.page.scss'],
})
export class HelpNewclientPage implements OnInit {

  constructor(
    private modalController:ModalController
  ) { }

  ngOnInit() {
  }

  onClick(){
    this.modalController.dismiss();

  }

}
