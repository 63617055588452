import { Injectable } from '@angular/core';
import { Motion, OrientationListener, RotationRate } from '@capacitor/motion';

import {
  ActionSheetController,
  LoadingController,
  ModalController,
  Platform,
  ToastController,
} from '@ionic/angular';

import { Observable, Subject } from 'rxjs';
import {
  Camera,
  CameraSource,
  CameraResultType,
  CameraDirection,
  ImageOptions,
} from '@capacitor/camera';
import { DomSanitizer } from '@angular/platform-browser';
import fixOrientation from 'fix-orientation-capacitor';
import { PluginListenerHandle } from '@capacitor/core';
import { Device } from '@capacitor/device';
import {decode} from "base64-arraybuffer";
import { environment } from 'src/environments/environment';
import { resolve } from 'dns';

declare var window: any;
interface IimageData {
  name: string;
  path: any;
}

const STORAGE_KEY = 'my_images';
@Injectable({
  providedIn: 'root',
})
export class ImageService {
  images = [];
  prom;
  myObservable$ = new Subject();
  ignore = false;

  constructor(
    public toastController: ToastController,
    public modalController: ModalController,
    public sanitizer: DomSanitizer,
    private toastCtrl:ToastController
  ) {}

  b64 = '';
  
  async selectImage(type?) {
return new Promise( async ( resolve , reject )=>{
  this.sendImageEvent('loading');
  let platform = localStorage.getItem(environment.platform)
   let  options = this.selectOptions(type , platform);
    await Camera.getPhoto(options)
      .then(async (originalImg) => {

        console.log("OriginalImage: ", originalImg );
        
        if( platform === 'web' ){
         let image = originalImg;
         
         let imageData: IimageData = {
          path: '',
          name: '',
        };

        const metab64 = image.dataUrl;
     
        imageData.path = this.sanitizer.bypassSecurityTrustResourceUrl(metab64);
        imageData.name = '.jpeg';
        console.log('IamgeData: ', imageData);

        resolve(imageData)


        }else{
        let imageData: IimageData = {
          path: '',
          name: '',
        };
      
        imageData.path = originalImg.webPath
        imageData.name = '.jpeg';
        resolve(imageData);
        }
        


       
      })
      .catch((err) => {

        const error = String(err).toLowerCase();
        console.log("Error: ", error );
        

        const substring = 'Out of memory';

        console.log("Substring: " , substring);
        console.log("Conditional: " , error.includes( substring.toLowerCase() )  );
        

        console.log("img serv err: ", err);
        if( error.includes( substring.toLowerCase() ) ){
          this.presentToast('Imagenes hasta 7Mb máximo porfavor.');
        }
        
        //
        this.sendImageEvent('cut');
        reject(err)
      });



} )



  }


  selectOptions( type:string , platform:string ){

    if( platform === 'web' ){

      return {
          quality: 90,
          source: CameraSource[type],
          resultType: CameraResultType.DataUrl
          ,
        };
  
      }else{ 
          return {
          quality: 90,
          source: CameraSource[type],
          resultType: CameraResultType.Uri
          ,
        };
      }
  
  }

  private imageEvent = new Subject<string>();
  sendImageEvent(data) {
    this.imageEvent.next(data);
  }

  getImageEvent(): Observable<any> {
    return this.imageEvent.asObservable();
  }

  resizeBytesImage(cropper, maxBytes) {
    return new Promise((resolve, reject) => { 

      let initialB64: string = cropper
      .getCroppedCanvas({
        width: 480,
        height: 360,
          fillColor: '#fff',
        })
        .toDataURL(`image/jpeg`, 1 );

        let initialWeight = initialB64.length * (3 / 4)
        console.log("initialWight: ", initialWeight );
        let finalQuality = maxBytes;
        let quality = 1;


        if( initialWeight > finalQuality){
          console.log("se procesara...");
          
          //si el maxbytes es igual o mayor al initialWeight de la iamgen, lo devolvemos 
          let percent = (finalQuality*100)/initialWeight
          let requiredCompressPercent = 100 - percent;
          console.log("Debemos comprimir al: ", requiredCompressPercent );
  
          //let quality = (requiredCompressPercent - 101.345)/(-32.545)
          console.log("quality: ", quality);
          const y = requiredCompressPercent;
          if(  requiredCompressPercent <= 81 ){
            // y = -4764.5x2 + 8489.9x - 3700.6
            
            const a = -4764.5;
            const b = 8489.9;
            const c = -3700.6 - y;
            const x1 = (-b + Math.sqrt( Math.pow(b , 2) - 4*a*c))/2*a;
            const x2 = (-b - Math.sqrt( Math.pow(b , 2) - 4*a*c))/2*a;

            quality = this.pickResult(x1 , x2)


          }else if( requiredCompressPercent>81 && requiredCompressPercent <=98  ){
            // y = -15.967x + 98.97 

            let x = (y - 98.97)/ -15.967;
            if( x>1 ){
              quality = 1;
            }else{
              quality = x;
            }


          }else if( requiredCompressPercent >98 && requiredCompressPercent <=100 ){
            quality = 1;
          }else{
            quality = 1;
          }
          

          //------------------ extraer la imagen--------------------------//
            
          let compressedImage: string = cropper
          .getCroppedCanvas({
            width: 480,
            height: 360,
              fillColor: '#fff',
            })
            .toDataURL(`image/jpeg`, quality );
          
            console.log("ompresseImge");
            let b64 = compressedImage.substr(compressedImage.lastIndexOf(',') + 1);
            let img = {
              C_URLIMG: compressedImage,
              C_BASE64IMG: b64,
              C_NAMIMG: '.jpeg',
            };
            resolve(img);
            return;


        }else{
          console.log("no se procesara...");
          
          //si el maxbytes es igual o mayor al initialWeight de la iamgen, lo devolvemos 
          let b64 = initialB64.substr(initialB64.lastIndexOf(',') + 1);
          let img = {
            C_URLIMG: initialB64,
            C_BASE64IMG: b64,
            C_NAMIMG: '.jpeg',
          };

          resolve(img);
            return;

        }



     


          

 
    });
  }






  pickResult(x1 , x2):number{

    if( x1 >= 0.855 && x1 <1 ){
      return x1;
    }

    if( x2 >= 0.855 && x2 <1 ){
      return x2;
    }

    return 1;

  }




  async presentToast(text) {
    const toast = await this.toastCtrl.create({
      message: text,
      position: 'middle',
      duration: 3000,
      color: 'warning',
    });
    toast.present();
  }










}
