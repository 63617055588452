import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/services/database.service';
import * as moment from 'moment'
import { LoaderService } from 'src/app/services/loader.service';
import { environment } from 'src/environments/environment';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-min-stock-modal',
  templateUrl: './min-stock-modal.page.html',
  styleUrls: ['./min-stock-modal.page.scss'],
})
export class MinStockModalPage implements OnInit , OnDestroy {

  getProductsWithMinStockSubs:Subscription;
  @Input() nav:string;
  showBack = false;

  constructor(
    private loaderService: LoaderService,
    private dbService: DatabaseService,
    private modalController:ModalController,
    private cdr:ChangeDetectorRef,
    private router: Router,
  ) {}

  ngOnInit() {
    console.log("MODALL nav: ", this.nav );
    
    if( this.nav == '1' ){
      this.showBack = true;
    }else{
      this.showBack = false;
    }
  }

  
  goBack(){
    this.router.navigate(['reports']);
  }

  ionViewWillEnter() {
    console.log('cargar la lista de productos con stocki minimo');
    this.loadProductsWithMinStock();
  }

  alertProducts = [];

  async loadProductsWithMinStock() {
    console.log('LOADDD');

    let request = {
      IDCOMPANY: localStorage.getItem(environment.idcompany),
      D_DATSAL: moment().format('YYYY-MM-DD'),
    };

    console.log('Loaader 1 ', request);
    await this.loaderService.show();
    console.log('Loadprod 2');

    this.getProductsWithMinStockSubs = this.dbService.getProductsWithMinStock(request).subscribe((data: any) => {
      console.log('products with min Stock: Loader 3 ', data.data);
      this.loaderService.hide();
      this.alertProducts = data.data;
      this.cdr.detectChanges();
    } , err=>{  this.loaderService.hide(); } );
  }



  ngOnDestroy(){
    this.getProductsWithMinStockSubs.unsubscribe();
  }








}
