
import { InterpageService } from "../services/interpage.service";
import { Injectable } from "@angular/core";
import { Resolve , ActivatedRouteSnapshot } from "@angular/router";

@Injectable({
    providedIn:"root"
})
export class PayMethodsResolver implements Resolve<any>{
    constructor(
        public interPageService : InterpageService
    ){}

    resolve( route:ActivatedRouteSnapshot ){

            console.log("Redirect a àymethods", this.interPageService.getIdHeaderSale() );
            


        return this.interPageService.getIdHeaderSale();

    }
}