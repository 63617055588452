import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { Injectable } from '@angular/core';
/* import { ConfigService } from '../services/config.service'; */
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { AlertController, ToastController } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class SendwarehouseService {
  datocodificado: any;
  datoscaneado: {};
  constructor(
    // private barcodeScanner: BarcodeScanner,
    public http: HttpClient,
    private alertController: AlertController,
    private toastController:ToastController
  ) {}

  async initScanner() {
    console.log('SE prepara el scanner: ', Capacitor.getPlatform());

    if (Capacitor.getPlatform() != 'web') {
      await BarcodeScanner.prepare();
    }
  }

  async destroyScanner() {
    console.log('sedestruye el compoente!');
    if (Capacitor.getPlatform() != 'web') {
      await BarcodeScanner.prepare();
    }
  }

  async checkPermission() {
    return new Promise(async (resolve, reject) => {
      const status = await BarcodeScanner.checkPermission({ force: true });
      if (status.granted) {
        //status.granted
        resolve(true);
      } else if (status.denied) {
        //status.denied
        const alert = await this.alertController.create({
          header: 'No se autorizo',
          message: 'Permita el uso de camara en su configuraciones',

          buttons: [
            {
              text: 'No',
              role: 'cancel',
            },
            {
              text: 'Abrir configuraciones',
              handler: () => {
                BarcodeScanner.openAppSettings();
                resolve(false);
              },
            },
          ],
        });
        await alert.present();
        //resolve(false);
      } else {
        console.log('Another tipe of barcode status: ', status);
        resolve(false);
      }
    });
  }

  async stopScanner() {
    await BarcodeScanner.stopScan();
    //this.scanActive = false;
  }

  async LeerCode() {
   


      return new Promise(async (resolve, reject) => {
        console.log('chekar permisos');
        const allowed = await this.checkPermission();
        console.log('Allowedd: ', allowed);

        if (allowed) {
          console.log('Abrir Scanner');
          const result = await BarcodeScanner.startScan(); // start scanning and wait for a result
          console.log('Resultado: ', result);
          if (result.hasContent) {
            // this.result = result.content;
            // this.scanActive = false;
            resolve({ code: result.content });
          }
        }
      });


   
  }




  async presentToast(msm: string) {
    const toast = await this.toastController.create({
      message: msm,
      position: 'middle',
      duration: 2000,
      color: 'success',
    });
    toast.present();
  }
}
